<template>
  <Tabs type="card" :animated="false" @on-click="toggleTabs" v-model="tabsName">
    <TabPane label="发言管理" name="speakManage">
      <Card>
        <h3 style="margin-bottom: 10px">议事名称：{{sparkTitleData.title}}</h3>
        <div>
          共 <span style="color: red">{{sparkTitleData.contactCount}}</span> 人
          <span style="margin: 0 30px">
            共 <span style="color: red">{{sparkTitleData.recordCount}}</span> 条发言记录
          </span>
          <span> 共 <span style="color: red">{{sparkTitleData.managerCount}}</span> 个管理员 </span>
        </div>
      </Card>
      <Form :model="speakForm" inline style="margin: 16px">
        <FormItem style="margin-bottom: 0px">
          <Checkbox
            border
            @on-change="
              (status) => {
                speakForm.atStatus = status ? '1' : '0';
                getSpeakManageData();
              }
            "
            >@我</Checkbox
          >
        </FormItem>
        <FormItem style="margin-bottom: 0px">
          <Checkbox
            border
            @on-change="
              (status) => {
                speakForm.adminStatus = status ? '1' : '0';
                getSpeakManageData();
              }
            "
            >管理员发言</Checkbox
          >
        </FormItem>
        <FormItem style="margin-bottom: 0px">
          <DatePicker
            transfer
            @on-change="speakChangeTime"
            type="datetimerange"
            placeholder="请选择开始和结束时间"
            style="width: 300px"
          ></DatePicker>
        </FormItem>
        <Button
        type="warning"
        icon="ios-download-outline"
        style="margin-right: 10px"
        @click="exportExcel"
        >导出全部</Button
      >
      <Button type="success" @click="exportWeek" icon="ios-download-outline"
        >导出本周</Button
      >
      </Form>
      <List border item-layout="vertical">
        <template v-if="speakData.length > 0">
          <ListItem v-for="item in speakData" :key="item.id">
          <ListItemMeta
            style="margin-bottom: 0px"
            :title="
              (item.nickname ? item.nickname + (item.addr ? '-' : '') : '') +
              (item.addr ? item.addr + (item.houseNum ? '-' : '') : '') +
              (item.houseNum ? item.houseNum + '号房' : '')
            "
          />
          <video :src="item.msg" v-if="item.msgType == '4'"></video>
          <audio :src="item.msg" v-else-if="item.msgType == '3'"></audio>
          <img
            :src="item.msg"
            style="width: 200px; max-height: 300px"
            v-else-if="item.msgType == '2'"
            alt=""
          />
          <article style="text-indent: 2em; text-align: start" v-else>
            {{ item.msg }}
          </article>
          <template slot="action">
            <li>{{ item.gmtCreate }}</li>
            <li>
              <Checkbox
              :disabled="$route.query.startend == 'end'"
                :value="
                  item.status == '0' ? true : item.status == '1' ? false : ''
                "
                @on-change="
                  (status) => changeStatus({ id: item.id, status: status })
                "
                >显示</Checkbox
              >
            </li>
            <li v-if="item.adminStatus == '1' || item.atStatus == '1'">
              <RadioGroup value="1" size="small" type="button" button-style="solid">
                <Radio  label="1" v-if="item.adminStatus == '1'">管理员</Radio>
                <Radio  label="1" v-if="item.atStatus == '1'">@我</Radio>
              </RadioGroup>
            </li>
          </template>
          <template slot="extra">
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <Button
                type="error"
                style="margin-bottom: 10px"
                v-if="$route.query.startend == 'start'"
                @click="deleteRecord(item.id)"
                >删除发言</Button
              >
              <Button type="primary" @click="replyRecord(item)" v-if="$route.query.startend == 'start'">回复</Button>
            </div>
          </template>
        </ListItem>
        </template>
        <ListItem v-else style="height: 130px;line-height: 106px;text-align: center">
           <i style="font-size: 24px;font-weight: bold">暂未发言记录</i> 
        </ListItem>
      </List>
      <Page style="margin: 16px;float: right" :page-size-opts="[20,30,50,100,200]" @on-change="changePage" @on-page-size-change="changePageSize" :total="speakTotal" :page-size="speakPageSize" :current="speakPage" show-elevator show-sizer show-total />
    </TabPane>
    <TabPane label="管理员管理" name="managerManage">
      <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;" v-if="$route.query.startend == 'start'">
        <span style="margin: 0 14px">设置管理员</span>
        <Button type="primary" size="small" style="margin-right: 10px" @click="allSelect">全选</Button>
        <Button type="warning" size="small" style="margin-right: 10px" @click="selectManager = []">全不选</Button>
        <Button type="success" size="small" @click="submitManager">提交</Button>
      </div>
       <CheckboxGroup v-if="managerData.length > 0" v-model="selectManager" style="display: flex;flex-wrap: wrap;justify-content: flex-start;">
        <Checkbox :disabled="$route.query.startend == 'end'" style="width: 30%;margin: 10px 0 20px 32px;" :label="item.custGlobalId + '&&' + item.name" v-for="(item,index) in managerData" :key="index">{{item.name}} - {{item.nickName}} - {{item.addr}}</Checkbox>
       </CheckboxGroup>
       <List border item-layout="vertical" v-else >
         <ListItem style="height: 130px;line-height: 106px;text-align: center">
           <i style="font-size: 24px;font-weight: bold">暂未管理员</i> 
        </ListItem>
       </List>
      

    </TabPane>
    <TabPane label="议事厅设置" name="discussSetting">
      <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:14px;">
        <span style="margin:0 14px">发送图片设置:</span>
        <RadioGroup v-model="discussSettingForm.chatImg" @on-change="(chatImg) => {$emit('changeDiscussRule',{businessId,chatImg})}">
          <Radio label="1" :disabled="$route.query.startend == 'end'">可以发图</Radio>
          <Radio label="2" :disabled="$route.query.startend == 'end'">禁止发图</Radio>
        </RadioGroup>
        <span style="margin-left: 50px;margin-right: 14px">发言设置:</span>
        <RadioGroup v-model="discussSettingForm.chatText" @on-change="(chatText) => {$emit('changeDiscussRule',{businessId,chatText})}">
          <Radio label="ON" :disabled="$route.query.startend == 'end'">正常发言</Radio>
          <Radio label="OFF" :disabled="$route.query.startend == 'end'">全体禁言 </Radio>
        </RadioGroup>
      </div>

      <Timeline>
        <div class="time-line" style=";margin: 0 28px" v-for="(item,index) in discussSettingData" :key="index">
          <div style="width: 145px;text-align: right;margin-right:14px">{{item.gmtCreate}}</div>
          <TimelineItem style="flex: 1" :class="[index == 0 ? 'firstTime' : '']">
            <span slot="dot"></span>
            <span v-if="index == 0" style="color: red;margin-left: 16px">当前公告</span>
            <Button size="small" type="primary" style="margin-left: 16px" @click="groupContent=item.content;changeGroupObj={groupId:item.groupId,id: item.id};groupStatus=true;" v-if="index == 0" :disabled="$route.query.startend == 'end'">修改</Button>
            <Button size="small" type="success" style="margin-left: 16px" @click="groupContent=item.content;groupStatus=true;" :disabled="$route.query.startend == 'end'">复制</Button>
            <Button size="small" type="error" style="margin-left: 16px" @click="delGroup(item.id)" :disabled="$route.query.startend == 'end'">删除</Button>
            <div style="padding: 10px;text-indent: 2em;margin: 10px;border: 1px solid #e8eaec;border-radius: 4px">{{item.content}}</div>
          </TimelineItem>
        </div>
      </Timeline>
    </TabPane>
    <TabPane label="户代表管理" name="contactManage">
       <div class="table-left">
        <Menu theme="light" :active-name="contactMenuName" @on-select="selectMenu">
            <MenuItem name="all"> 全部户代表 </MenuItem>
            <MenuItem name="gag"> 已禁言户代表 </MenuItem>
        </Menu>
      </div>
      <LiefengTable
         :tableData="contactManageData"
          :talbeColumns="contactManageColumns"
          :loading="contactLoading"
          :pageSize="contactPageSize"
          :pagesizeOpts="[20, 30, 50, 100, 200]"
          :total="contactTotal"
          :height="contactHeight"
          :curPage="contactPage"
          @hadlePageSize="getContactManageData"
      ></LiefengTable>
    </TabPane>
    <div slot="extra">
      <Button type="primary" style="margin-right: 10px" v-if="showSpeakBtn && $route.query.startend == 'start'" @click="openSpeak"
        >发言</Button
      >
      <Button type="primary" style="margin-right: 10px" v-if="showSettingBtn && $route.query.startend == 'start'" @click="groupStatus = true;"
        >新增公告</Button
      >
      <Input v-model.trim="contactName" style="width: 180px" placeholder="通过姓名或昵称搜索" v-if="showContactBtn"/>
      <Button type="primary" @click="searchContact" style="margin:0 10px" v-if="showContactBtn">搜索</Button>
      <Button type="primary" @click="contactName = '';searchContact()" style="margin-right: 10px" v-if="showContactBtn">重置</Button>
      <Poptip placement="bottom-end" popper-class="concatpoptipaa" width="600" :word-wrap='true' :transfe="true">
        <div slot="content">
          <Button style="margin: 5px" v-for="(item,index) in discussSettingForm.regions" :key="index">
            {{item.regionName}}
          </Button>
        </div>
        <Button type="success" style="margin-right: 10px" v-if="showContactBtn"
        >查看发布范围</Button
      >
      </Poptip>
      
      <Button type="error" @click="back">返回</Button>
    </div>
    <!-- 发言 -->
    <LiefengModal
      title="发言"
      width="500px"
     :value="speakStatus"
     @input="speakStatusFn"
    >
      <template #contentarea>
        <Input
          v-model="content"
          style="margin-bottom: 20px"
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 10 }"
          placeholder="请输入发言内容"
        />
        <div
          class="demo-upload-list"
          v-for="(item, index) in uploadList"
          :key="index"
        >
          <template v-if="item.status === 'finished'">
            <img :src="item.url" />
            <div class="demo-upload-list-cover">
              <Icon
                type="ios-trash-outline"
                @click.native="handleRemove(item)"
              ></Icon>
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info
            ></Progress>
          </template>
        </div>
        <Upload
          ref="upload"
          :show-upload-list="false"
          :on-success="handleSuccess"
          :format="['jpg', 'jpeg', 'png', 'gif']"
          :on-format-error="handleFormatError"
          :before-upload="handleBeforeUpload"
          :on-exceeded-size="handleMaxSize"
          multiple
          :max-size="5120"
          type="drag"
          :action="action"
          :data="uploadData"
          style="display: inline-block; width: 80px"
        >
          <div style="width: 80px; height: 80px; line-height: 80px">
            <Icon
              type="md-add"
              size="60"
              style="color: #bbb; margin: 8px auto"
            ></Icon>
          </div>
        </Upload>
      </template>
      <template #toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="speakStatusFn(false)"                                                                                                                
          >取消</Button
        >
        <Button type="primary" @click="saveSpeak">发送</Button>
      </template>
    </LiefengModal>
    <!-- 回复 -->
    <LiefengModal
      :title="'回复' + replyObj.nickname"
      width="500px"
      :value="replyStatus"
      @input="replyStatusFn"
    >
      <template #contentarea>
        <Input
          v-model="content"
          style="margin-bottom: 20px"
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 10 }"
          placeholder="请输入回复内容"
        />
      </template>
      <template #toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="replyStatusFn(false)"
          >取消</Button
        >
        <Button type="primary" @click="saveReply">确定</Button>
      </template>
    </LiefengModal>
    <!-- 群公告 -->
    <LiefengModal
      title="群公告详情"
      width="500px"
      :value="groupStatus"
      @input="groupStatusFn"
      class="modal"
    >
      <template #contentarea>
        <Input
          show-word-limit
          :maxlength="400"
          v-model="groupContent"
          style="margin-bottom: 20px"
          type="textarea"
          :autosize="{ minRows: 5, maxRows: 10 }"
          placeholder="请输入内容,不超过400字"
        />
      </template>
      <template #toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="cancelModalTip = true;groupStatusFn(false)"
          >取消</Button
        >
        <Button type="primary" @click="saveGroup">确定</Button>
      </template>
    </LiefengModal>
  </Tabs>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
  props: ["showName", "businessId"],
  data() {
    return {
      tabsName: "",
      showSpeakBtn: true,
      showContactBtn: false,
      showSettingBtn: false,
      // 发言管理
      speakForm: {},
      sparkTitleData: {},
      speakPage: 1,
      speakPageSize: 20,
      speakTotal: 0,
      speakData: [],
      //回复 
      replyObj: {},
      content: "",
      replyStatus: false,
    
      // 发言
      speakStatus: false,
      uploadList: [],
      imageName: '',
      imagePath: [],
      action: '',
      uploadData: {},
      // 管理员管理
      managerData: [],
      selectManager: [],

      // 议事厅设置
      discussSettingForm: {},
      discussSettingData: [],
      groupStatus: false,
      groupContent: '',
      changeGroupObj: {},

      // 户代表管理
      contactMenuName: "all",
      contactLoading: false,
      contactManageData: [],
      contactManageColumns: [
        {
          title: "头像",
          width: 120,
          key: 'portraitUrl',
          align: 'center',
          render: (h,params) => {
            return h('div',{
              style: {
                borderRadius: '50%',
                width: '80px',
                height: '80px',
                border: '2px solid #e8eaec',
                display: params.row.portraitUrl ? 'block' : 'none'
              }
            },[
              h('img',{
                style: {
                  width: '80px',
                  height: '80px',
                },
                attrs: {
                  src: params.row.portraitUrl
                }
              })
            ])
          }
        },
        {
          title: "姓名",
          width: 150,
          key: 'name',
          align: 'center'
        },
        {
          title: "昵称",
          width: 200,
          key: 'nickName',
          align: 'center'
        },
        {
          title: "性别",
          width: 120,
          key: 'sex',
          align: 'center'
        },
        {
          title: "所在详细地址",
          minWidth: 200,
          key: 'addr',
          align: 'center'
        },
        {
          title: "状态",
          width: 150,
          key: 'gag',
          align: 'center'
        },
        {
          title: "操作",
          width: 150,
          align: 'center',
          render: (h,params) => {
            return h('Button',{
              props: {
                type: params.row.gag == '可发言' ? 'primary' : 'error',
                disabled: this.$route.query.startend == 'end'
              },
              on: {
                click: () => {
                  let url = '';
                  if(params.row.gag == '可发言') {
                    url = '/sendmsg/pc/chat/discussBusiness/gagOnGroup'
                  }else {
                    url = '/sendmsg/pc/chat/discussBusiness/cancelGagOnGroup'
                  }
                  this.$post(url,{
                    admin: parent.vue.loginInfo.userinfo.realName,
                    businessId: this.businessId,
                    custGlobalId: params.row.custGlobalId,
                    oemCode: parent.vue.oemInfo.oemCode
                  }).then(res => {
                    if(res.code == 200) {
                      this.$Message.success({
                        background: true,
                        content: "操作成功"
                      });
                        this.getContactManageData({
                        page: this.contactPage,
                        pageSize: this.contactPageSize
                      })
                    }else {
                      this.$Message.error({
                        background: true,
                        content: res.desc
                      });
                    }
                  })
                }
              }
            }, params.row.gag == '可发言' ? '设为禁言' : '解除禁言')
          }
        },
      ],
      contactName: '',
      contactPage: 1,
      contactPageSize: 20,
      contactTotal: 0,
      contactHeight: ''
    };
  },
  methods: {
    // 户代表管理
    selectMenu(name) {
      this.contactName = '';
      this.contactMenuName = name;
      this.getContactManageData({
        page: this.contactPage,
        pageSize: this.contactPageSize
      })
    },
    searchContact() {
      this.getContactManageData({
        page: this.contactPage,
        pageSize: this.contactPageSize
      })
    },
    // 议事厅设置
    delGroup(id) {
      this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在删除群公告，此操作不可逆，是否继续？",
          onOk: () => {
            this.$post('/sendmsg/pc/chat/groupAffiche/deleteGroupAffiche',{
              id
            }).then(res => {
              if(res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功"
                });
                setTimeout(() => {
                  this.$Message.destroy()
                  this.getSettingData();
                },500)
              }
            })
          }
      })
    },
    saveGroup() {
      if(!this.groupContent) {
        this.$Message.error({
          background: true,
          content: "请输入群公告内容"
        });
        return;
      };
      this.$Message.loading({
        duration: 0,
        content: '正在保存，请稍等...'
      });
      let url = '';
      let params = {
        businessId: this.businessId,
        businessCode: '2',
        businessType: '2',
        content: this.groupContent,
        creator: parent.vue.loginInfo.userinfo.realName,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode
      };
      if(JSON.stringify(this.changeGroupObj) == '{}') {
        url = '/sendmsg/pc/chat/discussBusiness/addGroupAffiche';
      }else {
         url="/sendmsg/pc/chat/discussBusiness/updateGroupAffiche"
        params.groupId = this.changeGroupObj.groupId;
        params.id = this.changeGroupObj.id;
      }
      this.$post(url,params).then(res => {
        this.changeGroupObj = {};
        if(res.code == 200) {
          this.$Message.destroy();
          this.$Message.success({
            background: true,
            content: "操作成功"
          });
          setTimeout(() => {
            this.$Message.destroy()
            this.cancelModalTip = false;
            this.groupStatus = false;
            this.groupContent = '';
            this.getSettingData();
          },500)
        }else {
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        console.log(err);
        this.changeGroupObj = {};
        this.$Message.destroy();
        this.$Message.error({
            background: true,
            content: "新增失败，请联系管理员处理"
          })
      })
    },
    groupStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.groupStatus = status; 
            this.groupContent = '';
          },
        });
      } else {
        this.groupStatus = status;
      }
    },
    // 管理员管理
    allSelect() {
      this.selectManager = [];
      this.managerData.map(item => {
        if(this.selectManager.indexOf(item.custGlobalId + '&&' + item.name) == -1) {
          this.selectManager.push(item.custGlobalId + '&&' + item.name);
        }
      });
    },
    submitManager() {
      this.$post('/sendmsg/pc/chat/discussBusiness/addGroupManager',{
        businessId: this.businessId,
        chatManagerAddRoList: (() => {
          let arr = this.selectManager.map(item => {
            let obj = {
              manager: item.split('&&')[1],
              managerId: item.split('&&')[0]
            };
            return obj;
          });
          return arr;
        })(),
        oemCode: parent.vue.oemInfo.oemCode,
        operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      },{"Content-Type":"application/json"}).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "设置成功"
          })
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        console.log(err);
        this.$Message.error({
            background: true,
            content: "设置失败，请联系管理员处理"
          })
      })
    },
    // 发言管理分页
    changePage(page) {
      this.speakPage = page;
      this.getSpeakManageData({
        page: this.speakPage,
        pageSize: this.speakPageSize
      })
    },
    changePageSize(pageSize) {
      this.speakPageSize = pageSize;
      this.getSpeakManageData({
        page: this.speakPage,
        pageSize: this.speakPageSize
      })
    },
    //导出
    exportExcel() {
      this.$Message.loading({
        content: "正在导出数据，请稍等...",
        duration: 0,
      });
      let oneDayLong = 24 * 60 * 60 * 1000;
      let now = new Date();
      let sundayTime = now.getTime() + (7 - now.getDay()) * oneDayLong;
      let sunday = new Date(sundayTime);

      this.$.ajax({
        url:
          window.vue.getProxy()["/sendmsg"].target +
          "/upload/chatRecord/export",
        type: "POST",
        xhrFields: { responseType: "blob" },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          openId:window.sessionStorage.getItem("openId"),
          Authorization:window.sessionStorage.getItem('accessToken')
        },
        data: {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          endTime: new Date(
            this.$core.formatDate(sunday, "yyyy-MM-dd")
          ).getTime(),
          startTime: 0,
          groupId: this.sparkTitleData.groupId,
        },
        success: (res) => {
          if (res) {
            const blob = res;
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download =
              this.sparkTitleData.title + "本周发言记录.xlsx";
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
            this.$Message.destroy();
          } else {
            this.$Message.error({
              background: true,
              content: "暂未查询到发言记录",
            });
          }
        },
      });
    },

    exportWeek() {
      this.$Message.loading({
        content: "正在导出数据，请稍等...",
        duration: 0,
      });
      let oneDayLong = 24 * 60 * 60 * 1000;
      let now = new Date();
      let mondayTime = now.getTime() - (now.getDay() - 1) * oneDayLong;
      let sundayTime = now.getTime() + (7 - now.getDay()) * oneDayLong;
      let monday = new Date(mondayTime);
      let sunday = new Date(sundayTime);

      this.$.ajax({
        url:
          window.vue.getProxy()["/sendmsg"].target +
          "/upload/chatRecord/export",
        type: "POST",
        xhrFields: { responseType: "blob" },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          openId:window.sessionStorage.getItem("openId"),
          Authorization:window.sessionStorage.getItem('accessToken')
        },
        data: {
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          endTime: new Date(
            this.$core.formatDate(sunday, "yyyy-MM-dd")
          ).getTime(),
          startTime: new Date(
            this.$core.formatDate(monday, "yyyy-MM-dd")
          ).getTime(),
          groupId: this.sparkTitleData.groupId,
        },
        success: (res) => {
          if (res) {
            const blob = res;
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download =
              this.sparkTitleData.title + "本周发言记录.xlsx";
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
            this.$Message.destroy();
          } else {
            this.$Message.error({
              background: true,
              content: "暂未查询到发言记录",
            });
          }
        },
      });
    },
    // 发言
    openSpeak() {
      this.speakStatusFn(true);
    },
    speakStatusFn(status) {
      if (!status) {
        this.content = "";
        this.$refs.upload.fileList = [];
        this.uploadList = this.$refs.upload.fileList;
      }
      this.speakStatus = status;
    },
    // 循环发送
    callbackSpeak(msg,msgType) {
     return this.$post('/sendmsg/pc/chat/group/sendMessage',{
        businessCode: '2',
        businessType: '2',
        chatUserId: parent.vue.loginInfo.userinfo.custGlobalId,
        groupId: this.sparkTitleData.groupId,
        msg: msg,
        oemCode: parent.vue.oemInfo.oemCode,
        msgType: msgType
      })
    },
    saveSpeak() {
      if(!this.content && this.uploadList.length ==0) {
        this.$Message.error({
          background: true,
          content: "发言内容不能为空"
        });
        return;
      };
      this.$Message.loading({
        content: "正在发送，请稍等...",
        duration: 0
      })
      let promiseArr = [];
      if(this.content) {
        promiseArr.push(this.callbackSpeak(this.content,'1'))
      }
      if(this.uploadList.length > 0) {
        this.uploadList.map((item,index) => {
          promiseArr.push(this.callbackSpeak(item.url,'2'));
        })
      }
      Promise.all(promiseArr).then(res => {
        let tip = false;
        res.map((item,index) => {
          if(item.code == 200 && index == res.length -1 && !tip) {
            this.$Message.destroy();
            this.$Message.success({
              background: true,
              content: "发送成功"
            });
            setTimeout(() => {
              this.speakStatusFn(false);
              this.$Message.destroy();
              this.getSpeakManageData();
            },500)
          }else if(item.code != 200) {
            tip = true;
          }
        })
        if(tip) {
            this.$Message.error({
              background: true,
              content: "发送失败"
            })
          }
      })

     
    },
    // 上传图片
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "上传文件格式错误！",
        desc:
          "文件 " +
          file.name +
          " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
      });
    },
    handleBeforeUpload(file) {
      const check = this.uploadList.length < 3;
      if (!check) {
        this.$Notice.warning({
          title: "最多只能发送3张图片",
        });
        return check;
      } else {
        return this.$get(
          "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
        ).then((res) => {
          if (res.code == 200) {
            let now = this.$core.randomNumber();
            //防止文件名有.
            let suffixArr = file.name.split(".");
            let suffix = "." + suffixArr[suffixArr.length - 1];
            this.action = res.data.host;
            this.uploadData = {
              name: now + suffix,
              host: res.data.host,
              key: res.data.key + now + suffix,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
            this.imageName = now + suffix;
            this.imagePath.push(res.data.host + res.data.key + this.imageName);
          }
        });
      }
    },
    handleSuccess(res,file) {
      if (file.status == "finished") {
        file.url = this.imagePath[this.imagePath.length -1];
        this.imagePath.splice(this.imagePath.length -1,1);
        file.name = this.imageName
      } else {
        const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        this.$Message["error"]({
          background: true, 
          content: "图片上传失败！",
        });
      }
    },
    handleMaxSize(file) {
      this.$Notice.warning({
                    title: '上传失败',
                    desc: '文件  ' + file.name + ' 大小超出限制，请上传5M以内的图片'
                });
    },
    toggleTabs(name) {
      if (name == "speakManage") {
        this.showSpeakBtn = true;
        this.showContactBtn = false;
        this.showSettingBtn = false;
        if(this.speakData.length == 0) {
          this.getSpeakManageData();
        }
      } else if (name == "managerManage") {
        this.showSpeakBtn = false;
        this.showContactBtn = false;
        this.showSettingBtn = false;
        if(this.managerData.length == 0) {
          this.getManagerManageData();
        }
      }else if (name == "discussSetting") {
         this.showSpeakBtn = false;
        this.showContactBtn = false;
        this.showSettingBtn = true;
        if(this.discussSettingData.length == 0) {
          this.getSettingData();
        }
      } else if (name == "contactManage") {
        this.showSpeakBtn = false;
        this.showContactBtn = true;
        this.showSettingBtn = false;
        if(this.contactManageData.length == 0) {
          this.getContactManageData({
        page: this.contactPage,
        pageSize: this.contactPageSize,
      });
        }
      }
    },
    speakChangeTime(time) {
      if (time[0] && time[1]) {
        this.speakForm.startTime = new Date(time[0]).getTime();
        this.speakForm.endTime = new Date(time[1]).getTime();
      } else {
        this.speakForm.startTime = "";
        this.speakForm.endTime = "";
      }
      this.getSpeakManageData();
    },
    changeStatus(data) {
      if (data.id) {
        let params = {
          recordId: data.id,
          status: data.status ? 0 : 1,
        };
        this.$post("/sendmsg/pc/chat/record/changeRecordStatus", params)
          .then((res) => {
            if (res.code === "200") {
              this.$Message.success({
                background: true,
                content: "操作成功"
              });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 回复
    saveReply() {
      let content = this.content.replace(eval("/@" + this.replyObj.nickname + '/'),"").trim();
      if (!this.content) {
        this.$Message.error({
          background: true,
          content: "请输入回复内容",
        });
        return;
      }
      this.$post("/sendmsg/pc/chat/record/replyMessage", {
        chatRecordId: this.replyObj.id,
        content: content,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        groupId: this.replyObj.groupId,
        msgType: '1',
        oemCode: parent.vue.oemInfo.oemCode,
        replier: parent.vue.loginInfo.userinfo.realName,
        repliedGlobalId: this.replyObj.chatUserId
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "回复成功",
          });
          setTimeout(() => {
             this.replyStatus = false;
             this.$Message.destroy();
            this.getSpeakManageData();
          },500)
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    
    replyRecord(item) {
      this.content = "@" + item.nickname + " ";
      this.replyObj = item;
      this.replyStatus = true;
    },
    replyStatusFn(status) {
      if (!status) {
        this.content = "";
      }
      this.replyStatus = status;
    },
    //删除发言
    deleteRecord(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除发言记录，此操作不可逆，是否继续</p>",
        onOk: () => {
          this.$post("/sendmsg/pc/chat/record/deleteRecord", {
            chatRecordId: id,
            operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功",
                });
                setTimeout(() => {
                  this.$Message.destroy();
                  this.getSpeakManageData();
                }, 500);
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$Message.error({
                background: true,
                content: "删除失败，请联系管理员处理",
              });
            });
        },
      });
    },
    // 获取发言管理数据
    getSpeakManageData() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
       this.$get('/sendmsg/pc/chat/discussBusiness/queryStatGroup',{
         businessId: this.businessId,
      }).then(res => {
        if(res.code == 200 && res.data) {
          this.sparkTitleData = res.data;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
      this.$get("/sendmsg/pc/chat/discussBusiness/queryChatRecordPage", {
        ...this.speakForm,
        businessId: this.businessId,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        page: this.speakPage,
        pageSize: this.speakPageSize,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              if (item.gmtCreate) {
                item.gmtCreate = this.$core.formatDate(
                  new Date(item.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
                );
              }
            });
            this.speakData = res.dataList;
            this.speakPage = res.currentPage;
            this.speakPageSize = res.pageSize;
            this.speakTotal = res.maxCount;
            this.$Message.destroy();
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取管理员管理数据
    getManagerManageData() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$get("/sendmsg/pc/chat/discussBusiness/selectManagerList", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.managerData = res.dataList;
            this.$Message.destroy();
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
      this.$get("/sendmsg/pc/chat/discussBusiness/selectManagerByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.selectManager = [];
            res.dataList.map(item => {
              this.selectManager.push(item.managerId + '&&' + item.manager);
            });
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取议事厅设置数据
    getSettingData() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
      this.$get("/sendmsg/pc/chat/discussBusiness/queryBaseInfoByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.discussSettingForm = res.data;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
      this.$get("/sendmsg/pc/chat/discussBusiness/queryAfficheByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map(item => {
              if(item.gmtCreate) {
                item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate),'yyyy年MM月dd日 hh:mm')
              }
            });
            this.discussSettingData = res.dataList;
            this.$Message.destroy();
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取户代表管理数据
    getContactManageData(obj) {
      this.contactLoading = true;
      let url = "";
      if (this.contactMenuName == "all") {
        //全部
        url = "/sendmsg/pc/chat/discussBusiness/queryChatContactPage";
      } else {
        //被禁言
        url = "/sendmsg/pc/chat/discussBusiness/queryChatGagContactPage";
      }
      this.$get(url, {
        businessId: this.businessId,
        oemCode: parent.vue.oemInfo.oemCode,
        page: obj.page,
        pageSize: obj.pageSize,
        name: this.contactName,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map(item => {
              if(item.gag) {
                item.gag = item.gag == '0' ? '可发言' : '已禁言'
              };
              if(item.sex) {
                item.sex = item.sex == '1' ? '男' : item.sex == '2' ? '女' : '未知'
              }
            })
            this.contactManageData = res.dataList;
            this.contactPage = res.currentPage;
            this.contactPageSize = res.pageSize;
            this.contactTotal = res.maxCount;
            this.contactLoading = false;
          } else {
            this.contactLoading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.contactLoading = false;
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
        if(!this.discussSettingForm.regions) {
          this.$get("/sendmsg/pc/chat/discussBusiness/queryBaseInfoByBusinessId", {
        businessId: this.businessId,
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.discussSettingForm = res.data;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
        }
    },
    back() {
      this.$emit("closeDiscussSetting");
      this.$emit('saveNextSuccess');
    },
  },
  created() {
    this.tabsName = this.showName;
    this.toggleTabs(this.tabsName);
    this.contactHeight = window.innerHeight - 120 + '';
  },
  mounted() {
      this.uploadList = this.$refs.upload.fileList;
  },
  components: {
    LiefengTable,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.concatpoptipaa {
    max-height: calc(100vh - 120px);
    overflow: auto;
  } 
.demo-upload-list {
  display: inline-block;
  width: 84px;
  height: 84px;
  text-align: center;
  line-height: 84px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}

.time-line {
  display: flex;
  min-height: 120px;

  /deep/.ivu-timeline-item-tail {
    display: inline-block !important;
    border-left: 8px solid #e8eaec;
  }
  /deep/.firstTime {
    .ivu-timeline-item-tail {
      border-left: 8px solid #ed4014;
    }
    .ivu-timeline-item-head-custom {
      border-left: 8px solid #ed4014;
      background: #ed4014;
    }
  }
  /deep/.ivu-timeline-item-head-custom {
    text-align: center;
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #e8eaec;
    padding: 1px;
    top: 3px;
    left: -2px;
  }
}

.table-left {
  float: left;
  width: 160px;
  /deep/.ivu-menu {
    width: 160px !important;
  }
}
.modal {
  /deep/.ivu-modal-close {
    display: none;
  }
}
 /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
        background-color: #fff!important;
        color: #515a6e;
    }
     /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
</style>